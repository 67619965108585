<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table>
        <h1>{{ name }}</h1>
      </template>
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }} Table</h4>
              <div class="card-header-form">
                <div class="input-group">
                  <a
                    class="btn btn-primary mr-1"
                    data-toggle="collapse"
                    href="#collapseExample"
                    role="button"
                    aria-expanded="true"
                    aria-controls="collapseExample"
                    >Filter</a
                  >
                  <router-link
                    class="btn btn-primary mr-1"
                    :to="{ name: 'ProductCreate' }"
                    >Create</router-link
                  >
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search"
                    v-model="filter.search"
                    label="Search"
                    @keyup.enter="getData"
                  />
                  <div class="input-group-btn">
                    <button class="btn btn-primary" @click="getData">
                      <i class="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="collapse" id="collapseExample">
                <div class="card card-body">
                  <p class="font-weight-bold">Filter</p>
                  <div class="row">
                    <div class="col-6">
                      <model-list-select
                        :list="categories"
                        v-model="filter.category_id"
                        option-value="id"
                        option-text="name"
                        placeholder="Select Category"
                      >
                      </model-list-select>
                    </div>
                    <div class="col-6">
                      <model-list-select
                        :list="types"
                        v-model="filter.type_id"
                        option-value="id"
                        option-text="name"
                        placeholder="Select Type"
                      >
                      </model-list-select>
                    </div>
                    <div class="col-12">
                      <button
                        class="btn btn-primary w-100 mt-1"
                        @click="getData"
                      >
                        Filter Product
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <v-progress-circular
                v-if="loading"
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <v-data-table
                :headers="headers"
                v-if="!loading"
                :items="product.data"
                item-key="id"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:item="row">
                  <tr>
                    <td>
                      {{ row.item.name }}
                    </td>
                    <td>{{ row.item.sku }}</td>
                    <td>{{ row.item.category_name }}</td>
                    <td>{{ row.item.type_name || 'NO TYPE' }}</td>
                    <td>Rp. {{ formatPrice(row.item.price) }}</td>
                    <td>Rp. {{ formatPrice(row.item.selling_price) }}</td>
                    <td class="text-center">
                      <img
                        :src="row.item.image"
                        v-img
                        :alt="row.item.name"
                        height="100px"
                      />
                    </td>
                    <td>
                      <div class="d-flex gap-2 flex-wrap">
                        <router-link
                          :to="{
                            name: 'ProductShow',
                            params: { id: row.item.id },
                          }"
                          class="badge badge-success mr-1 mb-1"
                          >Show</router-link
                        >
                        <router-link
                          :to="{
                            name: 'ProductEdit',
                            params: { id: row.item.id },
                          }"
                          class="badge badge-warning mr-1 mb-1"
                          >Edit</router-link
                        >
                        <button
                          @click.prevent="(e) => deleteProduct(e, row.item.id)"
                          class="badge badge-danger mb-1"
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </div>
            <div class="card-footer text-right">
              <div class="d-inline-block overflow-auto">
                <v-pagination
                  v-model="page"
                  :length="product.meta.last_page"
                  :total-visible="product.meta.per_page"
                  @input="getData"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import swal from 'sweetalert';
import { apiGet } from '../../services/api';
export default {
  name: 'Product',
  data() {
    return {
      page: 1,
      pageVisible: 8,
      name: 'Product',
      product: [],
      filter: {
        search: '',
        category_id: '',
        product_code: '',
        type_id: '',
      },
      categories: [],
      types: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
    this.getCategories();
    this.getTypes();
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    detailProduct(id) {
      this.$router.push({
        name: 'ProductShow',
        params: { id },
      });
    },
    changePage(page) {
      this.page = page;
      this.getData();
    },
    resetFilter() {
      this.search = '';
      Object.entries(this.filter).forEach((e) => {
        this.filter[e[0]] = '';
      });
      this.getData().then(() => {
        this.page = 1;
      });
    },
    getCategories() {
      apiGet('product_category')
        .then((result) => {
          this.categories = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTypes() {
      apiGet('product_type')
        .then((result) => {
          this.types = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getData() {
      this.loading = true;
      axios
        .get(this.$store.state.api + 'product', {
          headers: {
            Authorization: this.$store.state.token,
          },
          params: {
            page: this.page,
            search: this.filter.search,
            category_id: this.filter.category_id,
            type_id: this.filter.type_id,
          },
        })
        .then((result) => {
          this.loading = false;
          this.product = result.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteProduct(e, id) {
      e.preventDefault();
      swal({
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this imaginary file!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axios
            .delete(this.$store.state.api + 'product/' + id, {
              headers: { Authorization: this.$store.state.token },
            })
            .then(() => this.getData())
            .catch((err) => {
              swal('Error!', err.message, 'error');
            });
          swal('Poof! Your product file has been deleted!', {
            icon: 'success',
          });
        } else {
          swal('Your product file is safe!');
        }
      });
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'SKU',
          value: 'sku',
        },
        {
          text: 'Category',
          value: 'category_id',
        },
        {
          text: 'Type',
          value: 'type_id',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Selling Price',
          value: 'selling_price',
        },
        {
          text: 'Image',
          value: 'image',
        },
        {
          text: 'Action',
          value: 'action',
        },
      ];
    },
  },
};
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
